/* eslint-disable react-hooks/exhaustive-deps */

import "./ReviewEmailEditScreen.scss";

import {
  Box,
  Button,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { IAPIConfig, useApi } from "../../custom_hooks/useApi";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import AlertCustomDialog from "../../components/Dialog/AlertCustomDialog";
import CustomToast from "../../utils/cutomToast/CustomToast";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useSelector } from "react-redux";

// import CopyAllIcon from "@mui/icons-material/CopyAll";



// import RemoveIcon from "@mui/icons-material/Remove";

// import { useSelector } from "react-redux";

// import { useTheme } from "@mui/system";

//import DeleteIcon from "@mui/icons-material/Delete";
//import AddIcon from "@mui/icons-material/Add";



const ReviewEmailEditScreen: React.FC = () => {
  // const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const location = useLocation();
  const { doApi } = useApi();
  const { activity: data, userUniqueId, action, campaignName } = location.state;
  const profileData = useSelector(
    (state: any) => state.businessDetails.profileDataByUniqueID
  );

  const [subjectLine, setSubjectLine] = useState(data?.EmailInfo?.Subject);
  const [content, setContent] = useState(data?.EmailInfo?.Content);
  const [buttonText, setButtonText] = useState(data?.EmailInfo?.SendText);
  // const [reviewLinkOption, setReviewLinkOption] = useState<string>(
  //   data?.Link?.Type
  // );
  const reviewLinkOption = data?.Link?.Type;
  // const [reviewLinkUrl, setReviewLinkUrl] = useState<string>(data?.Link?.Url);
  const reviewLinkUrl = data?.Link?.Url;
  const [htmlContent, setHtmlContent] = useState("");

  useEffect(() => {
    const generatedHtml = `
    <div style="font-family: Arial, sans-serif; line-height: 1.5;">
      <h1 style="font-size: 18px; color: #333; text-align: left;">
        ${profileData?.Name}
      </h1>
      <p style="font-size: 16px; color: #555; white-space: pre-wrap; margin: 20px 0;">
        ${content}
      </p>
      <div style="text-align: left;">
        <a href="${reviewLinkUrl}" style="display: inline-block; padding: 10px 20px; background-color: #0F4343; color: #fff; text-decoration: none; border-radius: 4px;">
          ${buttonText}
        </a>
      </div>
    </div>
    `;

    setHtmlContent(generatedHtml);
  }, [subjectLine, content, buttonText, reviewLinkUrl, profileData?.Name]);

  // const [selectedDays, setSelectedDays] = useState<string[]>(
  //   data?.Schedule?.SendOnDays || []
  // );
  const selectedDays = data?.Schedule?.SendOnDays || [] ;
  // const transformTimeSlots = (sendAtTime: any) => {
  //   return (
  //     sendAtTime?.map((slot: any) => ({
  //       id: Date.now(),
  //       start: slot.StartTime,
  //       end: slot.EndTime,
  //     })) || [{ id: Date.now(), start: "10:00", end: "12:00" }]
  //   );
  // };
  // const [timeSlots, setTimeSlots] = useState(
  //   transformTimeSlots(data?.Schedule?.SendAtTime)
  // );
  const transformTimeSlots = (sendAtTime: any) => {
    return (
      sendAtTime?.map((slot: any) => ({
        id: Date.now(),
        start: slot.StartTime,
        end: slot.EndTime,
      })) || [{ id: Date.now(), start: "10:00", end: "12:00" }]
    );
  };
  const timeSlots = transformTimeSlots(data?.Schedule?.SendAtTime)

  // const handleDayChange = (day: string) => {
  //   setHasUnsavedChanges(true);
  //   setSelectedDays((prevSelectedDays) =>
  //     prevSelectedDays.includes(day)
  //       ? prevSelectedDays.filter((selectedDay) => selectedDay !== day)
  //       : [...prevSelectedDays, day]
  //   );
  // };

  // const handleAddTimeSlot = () => {
  //   setHasUnsavedChanges(true);
  //   setTimeSlots([
  //     ...timeSlots,
  //     { id: Date.now(), start: "10:00", end: "12:00" },
  //   ]);
  // };

  // const handleRemoveTimeSlot = (id: number) => {
  //   setTimeSlots(timeSlots.filter((slot: any) => slot.id !== id));
  // };

  // const handleTimeChange = (
  //   id: number,
  //   field: "start" | "end",
  //   value: string
  // ) => {
  //   setHasUnsavedChanges(true);
  //   setTimeSlots(
  //     timeSlots.map((slot: any) =>
  //       slot.id === id ? { ...slot, [field]: value } : slot
  //     )
  //   );
  // };

  //   const formattedCustomerName = customerName.split(',').join(', ');

  // const [mailSendingOption, setMailSendingOption] = useState(
  //   data?.EmailInfo?.Frequency || "anytime"
  // );
  
  const mailSendingOption = data?.EmailInfo?.Frequency || "anytime";

  // const mailSendingHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setMailSendingOption(event.target.value);
  //   setHasUnsavedChanges(true);
  // };

  // const isDisabled = mailSendingOption === "anytime";

  const [dialogState, setDialogState] = useState<{
    alertDialog: boolean;
  }>({
    alertDialog: false,
  });
  // const handleCopyToClipboard = (text: string) => {
  //   navigator.clipboard.writeText(text).then(
  //     () => console.log("Link copied to clipboard!"),
  //     (err) => console.error("Failed to copy text: ", err)
  //   );
  // };

  // Function to toggle dialogs
  const toggleDialog = (dialogName: keyof typeof dialogState) => {
    setDialogState((prevState) => ({
      ...prevState,
      [dialogName]: !prevState[dialogName],
    }));
  };
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [type, setType] = useState("Default");

  useEffect(() => {
    if (hasUnsavedChanges) {
      setType("Custom");
    }
  }, [hasUnsavedChanges]);

  const handleSave = async () => {
    const updatedData = {
      businessId: data?.BusinessId,
      locationId: data?.LocationId,
      name: data?.Name,
      activityId: data?.UniqueId,
      mode: "EMAIL",
      link: {
        type: reviewLinkOption,
        url: reviewLinkUrl,
      },
      daysLater: data?.DaysLater,
      emailInfo: {
        type: type,
        frequency: mailSendingOption,
        subject: subjectLine,
        content: content,
        fullContent: htmlContent,
        sendText: buttonText,
      },
      smsInfo: {
        type: "",
        frequency: "",
        text: "",
        fullContent: "",
      },
      schedule: {
        sendOnDays: selectedDays,
        sendAtTime: timeSlots.map((slot: any) => ({
          startTime: slot.start,
          endTime: slot.end,
        })),
        count: timeSlots.length,
      },
    };

    try {
      const updateActivityConfig: IAPIConfig = {
        URL: `/Campaign/UpdateCampaignActivity?userUniqueId=${userUniqueId}`,
        method: "POST",
        payLoad: updatedData,
      };
      const response: any = await doApi(updateActivityConfig, "seedcore");
      if (response.data.err === false) {
        CustomToast(
          response.data.message || "Campaign Updated Successfully",
          "success"
        );
        navigate(-1);
      } else {
        CustomToast(
          response.data.message || "Campaign Not Updated Successfully",
          "error"
        );
      }
    } catch (error: any) {
      console.error("Error saving data:", error);
      CustomToast(error.message, "error");
    }
  };

  const handleExitClick = () => {
    if (hasUnsavedChanges) {
      toggleDialog("alertDialog");
    } else {
      navigate(-1);
    }
  };

  return (
    <Box className="review-email-edit-screen-container">
      <Stack direction="row" justifyContent="space-between">
        <Box>
          <Typography variant="body1" sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
            {action === "update" ? campaignName : ""}
          </Typography>
          <Typography variant="h5">{data?.Name}</Typography>
        </Box>
        <Box>
          <Button
            sx={{ marginRight: 2 }}
            variant="outlined"
            color={hasUnsavedChanges ? "error" : undefined}
            onClick={handleExitClick}
          >
            {hasUnsavedChanges ? "Exit Without Saving" : "Exit"}
          </Button>
          <Button variant="contained" onClick={handleSave}>
            Save
          </Button>
        </Box>
      </Stack>
      <Typography variant="subtitle1" gutterBottom sx={{ marginTop: 3 }}>
        Email Message
      </Typography>
      <Stack
        direction={{ md: "column", lg: "row" }}
        spacing={8}
        justifyContent={"space-between"}
        sx={{ width: "100%" }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Stack direction="column" rowGap={1}>
            <TextField
              label="Subject"
              variant="filled"
              fullWidth
              value={subjectLine}
              onChange={(e) => {
                setSubjectLine(e.target.value);
                setHasUnsavedChanges(true);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <RefreshIcon
                      color="primary"
                      sx={{
                        width: "24px",
                        height: "24px",
                        marginBottom: -2,
                        cursor: "pointer",
                      }}
                      onClick={() => setSubjectLine("")}
                    />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label="Content"
              variant="filled"
              fullWidth
              multiline
              rows={4}
              value={content}
              onChange={(e) => {
                setContent(e.target.value);
                setHasUnsavedChanges(true);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <RefreshIcon
                      color="primary"
                      sx={{
                        width: "24px",
                        height: "24px",
                        marginBottom: -8,
                        cursor: "pointer",
                      }}
                      onClick={() => setContent("")}
                    />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label="Button Text"
              variant="filled"
              fullWidth
              value={buttonText}
              onChange={(e) => {
                setButtonText(e.target.value);
                setHasUnsavedChanges(true);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <RefreshIcon
                      color="primary"
                      sx={{
                        width: "24px",
                        height: "24px",
                        marginBottom: -2,
                        cursor: "pointer",
                      }}
                      onClick={() => setButtonText("")}
                    />
                  </InputAdornment>
                ),
              }}
            />
            {/* <Box sx={{ marginTop: 3, marginBottom: 3 }}>
              <Typography variant="subtitle1" id="reviewlink">
                Review Link
              </Typography>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={1}
                sx={{ marginTop: 1 }}
              >
                <FormControl
                  variant="outlined"
                  sx={{ minWidth: { xs: "100%", sm: "240px" } }}
                >
                  <Select
                    value={reviewLinkOption}
                    onChange={(e) => {
                      setReviewLinkOption(e.target.value);
                      setHasUnsavedChanges(true);
                    }}
                    size="small"
                  >
                    <MenuItem value="customlink">Custom Link</MenuItem>
                    <MenuItem value="googlebusinesslink">
                      Google Business Link
                    </MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  margin="normal"
                  value={reviewLinkUrl}
                  onChange={(e: any) => {
                    setReviewLinkUrl(e.target.value);
                    setHasUnsavedChanges(true);
                  }}
                  defaultValue={"http://wwww.autogeneratedlink.com/"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CopyAllIcon
                          color="primary"
                          sx={{ cursor: "pointer" }}
                          onClick={() => handleCopyToClipboard(reviewLinkUrl)}
                        />
                      </InputAdornment>
                    ),
                  }}
                  sx={{ color: "rgba(0, 0, 0, 0.38)" }}
                />
              </Stack>
            </Box> */}
          </Stack>

          {/* <Box className="schedule-settings">
            <Typography variant="subtitle1" id="reviewlink">
              Send Mail
            </Typography>

            <RadioGroup value={mailSendingOption} onChange={mailSendingHandler}>
              <FormControlLabel
                value="anytime"
                control={<Radio />}
                label="Immediately"
              />
              <FormControlLabel
                value="custom"
                control={<Radio />}
                label="Custom Schedule"
              />
            </RadioGroup>

            <Box>
              <Box sx={{ marginTop: 1 }}>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "0.75rem", color: "rgba(0, 0, 0, 0.6)" }}
                >
                  Emails will only go out on selected days and times below
                </Typography>
                <ToggleButtonGroup
                  value={selectedDays}
                  exclusive
                  aria-label="Platform"
                  sx={{
                    marginTop: 1,
                    height: "40px",
                    fontWeight: "bold",
                    fontSize: "13px",
                    textTransform: "capitalize",
                    color: "red",
                    flexWrap: isMobile ? "wrap" : "nowrap",
                    justifyContent: isMobile ? "space-evenly" : "center", // More even spacing on mobile
                    gap: isMobile ? 1 : 0,
                    padding: "4px 0",
                  }}
                  size="small"
                  disabled={isDisabled} // Disable the days selection when "Anytime" is selected
                >
                  {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map(
                    (day) => (
                      <ToggleButton
                        key={day}
                        value={day}
                        selected={selectedDays.includes(day)}
                        onClick={() => handleDayChange(day)}
                        sx={{
                          textTransform: "capitalize",
                          padding: "0 8px",
                          width: "57.14px",
                          color: "rgba(0, 0, 0, 0.87)",
                          border: "1px solid rgba(0, 0, 0, 0.23)",
                        }}
                      >
                        {day}
                      </ToggleButton>
                    )
                  )}
                </ToggleButtonGroup>
              </Box>
              <Box>
                {timeSlots.map((slot: any) => (
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={{ xs: 1, sm: 2 }}
                    key={slot.id}
                    sx={{ marginTop: { xs: 3, sm: 1 } }}
                  >
                    <TextField
                      type="time"
                      value={slot.start}
                      size="small"
                      sx={{ width: "177.5px" }}
                      onChange={(e) =>
                        handleTimeChange(slot.id, "start", e.target.value)
                      }
                      disabled={isDisabled} // Disable start time field when "Anytime" is selected
                    />
                    <Box>
                      <RemoveIcon
                        sx={{ width: "8px", color: "rgba(0, 0, 0, 0.87)" }}
                      />
                    </Box>
                    <TextField
                      type="time"
                      value={slot.end}
                      size="small"
                      sx={{ width: "177.5px" }}
                      onChange={(e) =>
                        handleTimeChange(slot.id, "end", e.target.value)
                      }
                      disabled={isDisabled} // Disable end time field when "Anytime" is selected
                    />
                   <IconButton disabled={isDisabled}>
                      <DeleteIcon
                        sx={{
                          width: "1.5rem",
                          height: "1.5rem",
                          cursor: "pointer",
                          color: isDisabled
                            ? "rgba(0, 0, 0, 0.6)"
                            : "rgba(15, 67, 67, 1)",
                        }}
                        onClick={() => handleRemoveTimeSlot(slot.id)}
                      />
                    </IconButton> 
                  </Stack>
                ))}
                 <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={handleAddTimeSlot}
                  sx={{ marginTop: 1 }}
                  disabled={isDisabled} // Disable Add button when "Anytime" is selected
                >
                  Add
                </Button> 
              </Box>
            </Box>
          </Box> */}
        </Box>

        <Box>
          {/* Preview Area */}
          <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
            Preview
          </Typography>

          <Stack className="phone-frame">
            <Typography variant="subtitle1" className="phone-frame-title">
              {subjectLine}
            </Typography>
            <Box className="phone-frame-content">
              <Box>
                <Typography variant="h6" className="title">
                  {profileData?.Name}
                </Typography>
                <Typography
                  variant="body1"
                  className="content"
                  sx={{ whiteSpace: "pre-wrap" }}
                >
                  {content}
                  <br />
                </Typography>
              </Box>
              <Box className="submit-btn">
                <Button variant="contained" color="primary">
                  {buttonText}
                </Button>
              </Box>
            </Box>
          </Stack>
        </Box>
      </Stack>

      {/* alert dialog */}
      <AlertCustomDialog
        open={dialogState.alertDialog}
        onClose={() => toggleDialog("alertDialog")}
        title="Are you sure you want to exit without saving?"
        minWidth="600px"
      >
        <Box
          className="line"
          sx={{
            width: "100%",
            height: "0.0625rem",
            opacity: "0.2",
            background: "var(--Schemes-On-Secondary, red)",
          }}
        ></Box>

        <Stack direction="row" spacing={2} justifyContent="center">
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              navigate(-1);
            }}
          >
            Yes, exit without saving
          </Button>
          <Button
            variant="contained"
            onClick={() => toggleDialog("alertDialog")}
          >
            Never mind
          </Button>
        </Stack>
      </AlertCustomDialog>
    </Box>
  );
};

export default ReviewEmailEditScreen;
