import React, { useEffect, useState } from "react";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import countries from "../countries.json";

type Country = {
  name: string;
  flag: string;
  code: string;
  dial_code: string;
};

const CountryAutocomplete: React.FC<{
  onCountrySelect: (country: Country | null) => void;
}> = ({ onCountrySelect }) => {
  const defaultCountry = countries.find((country) => country.code === "US");
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(
    defaultCountry || null
  );

  useEffect(() => {
    if (defaultCountry) {
      onCountrySelect(defaultCountry);
    }
  }, [defaultCountry, onCountrySelect]);

  return (
    <Autocomplete
      options={countries}
      value={selectedCountry}
      getOptionLabel={(option) => `(${option.dial_code}) ${option.name}`}
      renderOption={(props, option) => (
        <li {...props} style={{ whiteSpace: "nowrap", fontSize: "12px" }}>
          ({option.dial_code}) {option.name} 
        </li>
      )}
      onChange={(_, value: Country | null) => {
        setSelectedCountry(value);
        onCountrySelect(value);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Choose a country"
          variant="outlined"
          sx={{
            height: "40px", // Adjust input height
            fontSize: "14px",
            "& .MuiInputBase-root": {
              height: "40px", // Adjust input height
              fontSize: "14px"
            },
          }}
        />
      )}
      sx={{
        "& .MuiAutocomplete-listbox": {
          maxWidth: "none",
          minWidth: "50px",
          fontSize: "14px"
        },
        "& .MuiAutocomplete-inputRoot": {
          minWidth: "150px",
          fontSize: "14px"
        },
      }}
    />
  );
};

export default CountryAutocomplete;
